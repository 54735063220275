import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { StackScreenProps } from "@react-navigation/stack";
import * as React from 'react';
import { RefreshControl, ScrollView } from "react-native";
import { Divider, ListItem } from "react-native-elements";
import 'react-native-gesture-handler';
import { Button } from "react-native-paper";
import { getAppContext } from "../../AppProvider";
import * as Alert from "../../alerts/alerts";
import * as Backend from "../../backend/Backend";
import * as Style from '../../theme/style';
import { PrinterTypeEnum } from "../../types/PrinterTypeEnum";
import { RootStackParamList } from "../../types/RootStackParamList";
import { ScreenState } from "../../types/ScreenState";
import { StorePrinter } from "../../types/StorePrinter";
import { ErrorView } from "../elements/ErrorView";
import { LoadingView } from "../elements/LoadingView";

export default function PrintersScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Printers'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Backend.loadStoreData("printers/", setState, appContext).then(() => {})
	}
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
				<Button onPress={() => appContext.openDialog({
					title: "Drucker hinzufügen",
					message: "Bitte gib die Adresse an, unter der der Drucker zu erreichen ist. Bitte nutze dafür das Format 'Hostname:Port' (Standard-Port: 9100). Wähle zudem bitte den Typ des Druckers aus.",
					input_visible: true,
					input_keyboardType: 'default',
					input_placeholder: "192.168.0.99:9100",
					picker_visible: true,
					picker_items: Object.entries(PrinterTypeEnum).filter(([k]) => isNaN(parseInt(k))).map(([k, v]) => ({label: v, value: k})),
					positiveButton_label: "Hinzufügen",
					positiveButton_onPress(inputValue?: string, pickerValue?: string) {
						if (inputValue) {
							let hostAddress: string = inputValue;
							let port: number = 9100;

							if (inputValue.includes(':')) {
								const parts = inputValue.split(':')
								hostAddress = parts[0]
								port = parseInt(parts[1])
							}

							Backend.sendStoreData("printers", setState, appContext, "PUT", {
								printerType: pickerValue || 'RECEIPT',
								displayName: inputValue,
								hostAddress: hostAddress,
								port: port,
							}).then(() => {})
						}
					},
					negativeButton_visible: true,
					negativeButton_label: "Abbrechen",
				})}>
					<FontAwesomeIcon icon="plus" size={24} style={[styles.text]}/>
				</Button>
			),
		});
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const printers: StorePrinter[] = state.response.data
		return (
			<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
				{
					printers.map((printer, i) =>
						<ListItem key={"printer" + i} topDivider bottomDivider
								  containerStyle={[styles.background]}
								  onPress={() => navigation.navigate('EditPrinter', {printerId: printer.id})}>
							<ListItem.Content style={[]}>
								<ListItem.Title style={[styles.text, styles.title]}>{printer.displayName}</ListItem.Title>
								<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Address: {printer.hostAddress}:{printer.port}</ListItem.Subtitle>
							</ListItem.Content>
							<ListItem.Content style={[{flex: 1}]} right={true}>
								<ListItem.Title style={[styles.text, styles.title]}>{printer.printerType}</ListItem.Title>
							</ListItem.Content>
						</ListItem>
					)
				}
				<Divider style={[styles.divider]} />
			</ScrollView>
		);
	}
}
