import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {getAppContext} from '../../AppProvider';
import {RefreshControl, ScrollView} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import {RootStackParamList} from "../../types/RootStackParamList";
import {StackScreenProps} from '@react-navigation/stack';
import {Report} from "../../types/Report";
import {LocalizationContext} from "../../LocalizationContext";
import * as Alert from '../../alerts/alerts';
import moment from "moment";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";

export default function ReportDetailScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'ReportDetail'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { t, locale } = React.useContext(LocalizationContext);

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const reportIdentifier = route.params.reportIdentifier
	function loadData() {
		if (reportIdentifier !== undefined) {
			Backend.loadStoreData("report/" + reportIdentifier.type + "/" + reportIdentifier.id, setState, appContext).then(() => {})
		}
	}
	React.useEffect(() => {
		if (reportIdentifier === undefined) {
			Backend.sendStoreData("report/z", setState, appContext, "POST", undefined, true).then(() => {})
		}
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	function printReport() {
		if (reportIdentifier !== undefined) {
			Backend.sendStoreData("report/" + reportIdentifier.type + "/" + reportIdentifier.id + "/print", setState, appContext, "POST", undefined, false).then(() => {})
		}
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen",
				"Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const zReport = state.response.data as Report;
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					<ListItem key={"print"} topDivider bottomDivider
										containerStyle={[styles.background]}
										onPress={printReport}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.text, styles.title]}>Bericht erneut ausdrucken</ListItem.Title>
						</ListItem.Content>
						<ListItem.Chevron />
					</ListItem>
					<Divider style={[styles.divider]} />
					{
						zReport.type === "Z" &&
						<ListItem key={"zId"} topDivider bottomDivider
											containerStyle={[styles.background]}>
							<ListItem.Content style={[]}>
								<ListItem.Title style={[styles.text, styles.title]}>Z-Id</ListItem.Title>
							</ListItem.Content>
							<ListItem.Content style={[]} right={true}>
								<ListItem.Title style={[styles.text]}>{zReport.zId}</ListItem.Title>
							</ListItem.Content>
						</ListItem>
					}
					<ListItem key={"Time"} topDivider bottomDivider
										containerStyle={[styles.background]}>
						<ListItem.Content style={[{flex: 1}]}>
							<ListItem.Title style={[styles.text, styles.title]}>Start</ListItem.Title>
							<ListItem.Subtitle style={[styles.text]}>{moment(zReport.startTime, "YYYY-MM-DDTkk:mm:ssSSSZ").format("DD. MMM LT")}</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Content style={[{flex: 1}]} right={true}>
							<ListItem.Title style={[styles.text, styles.title]}>End</ListItem.Title>
							<ListItem.Subtitle style={[styles.text]}>{moment(zReport.endTime, "YYYY-MM-DDTkk:mm:ssSSSZ").format("DD. MMM LT")}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"revenue"} topDivider bottomDivider
										containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.text, styles.title]}>Umsatz</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>(inkl. Trinkgeld)</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Content style={[]} right={true}>
							<ListItem.Title style={[styles.text, styles.textRight]}>Brutto: {zReport.revenueGross.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>Netto: {zReport.revenueNet.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"totalTipTaxFree"} topDivider bottomDivider
										containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.text, styles.title]}>Trinkgeld</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>(Steuerfrei)</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Content style={[]} right={true}>
							<ListItem.Title style={[styles.text, styles.textRight]}>{zReport.totalTipTaxFree.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					<ListItem key={"totalTipTaxed"} topDivider bottomDivider
										containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.text, styles.title]}>Trinkgeld</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>(Steuerpflichtig)</ListItem.Subtitle>
						</ListItem.Content>
						<ListItem.Content style={[]} right={true}>
							<ListItem.Title style={[styles.text, styles.textRight]}>Netto: {zReport.totalTipTaxedNet.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>Brutto: {zReport.totalTipTaxedGross.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					<ListItem key={"userReportsHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.text, styles.title]}>Kellnerberichte</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					{zReport.userReports.map((userReport, i) =>
							<ListItem key={"userReport " + i} topDivider bottomDivider
												containerStyle={[styles.background]}
												onPress={() => {
													Alert.alert("User Report", JSON.stringify({...userReport, user: undefined}))
												}}>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>{userReport.user.displayName}</ListItem.Title>
								</ListItem.Content>
								<ListItem.Content style={[]} right={true}>
									<ListItem.Title style={[styles.text, styles.textRight]}>Brutto: {userReport.revenueGross.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
									<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>Netto: {userReport.revenueNet.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
								</ListItem.Content>
								<ListItem.Chevron/>
							</ListItem>
					)}
					<Divider style={[styles.divider]} />
					<ListItem key={"hourlyReportsHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.text, styles.title]}>Stundenberichte</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					{zReport.hourlyReport.map((timeStatistic, i) =>
							<ListItem key={"timeStatistic " + i} topDivider bottomDivider containerStyle={[styles.background]}>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>
										{moment(timeStatistic.startTime, "YYYY-MM-DDTkk:mm:ssSSSZ").format("LT")} - {moment(timeStatistic.endTime, "YYYY-MM-DDTkk:mm:ssSSSZ").format("LT")}
									</ListItem.Title>
									<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Neue Gäste: {timeStatistic.guests}</ListItem.Subtitle>
								</ListItem.Content>
								<ListItem.Content style={[]} right={true}>
									<ListItem.Title style={[styles.text, styles.textRight]}>{timeStatistic.sum.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
								</ListItem.Content>
							</ListItem>
					)}
					<Divider style={[styles.divider]} />
					<ListItem key={"paymentsHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.text, styles.title]}>Zahlungen</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					{
						Object.entries(zReport.payments).map(([type, stats]) => {
							return <ListItem key={"payments" + type} topDivider bottomDivider
															 containerStyle={[styles.background]}>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>{t('paymentType_' + type)} ({stats.count}x) Summe:</ListItem.Title>
									<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Davon Trinkgeld:</ListItem.Subtitle>
								</ListItem.Content>
								<ListItem.Content style={[]} right={true}>
									<ListItem.Title style={[styles.text, styles.textRight]}>{stats.sum.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
									<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.textRight]}>{stats.tip.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
								</ListItem.Content>
							</ListItem>
						})
					}
					<Divider style={[styles.divider]} />
					<ListItem key={"taxesHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.text, styles.title]}>Steuern</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					{
						zReport.taxes.map((tax, i) => {
							return <ListItem key={"taxGroup" + i} topDivider bottomDivider
															 containerStyle={[styles.background]}>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>{t('taxGroup_' + tax.TaxG)} ({tax.Prc.toLocaleString(locale, {style: 'decimal'})}%)</ListItem.Title>
								</ListItem.Content>
								<ListItem.Content style={[]} right={true}>
									<ListItem.Subtitle style={[styles.text, styles.textRight]}>Netto: {tax.Net.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
									<ListItem.Subtitle style={[styles.text, styles.textRight]}>Steuer: {tax.TAmt.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
									<ListItem.Subtitle style={[styles.text, styles.textRight]}>Brutto: {tax.Amt.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
								</ListItem.Content>
							</ListItem>
						})
					}
					<Divider style={[styles.divider]} />
					<ListItem key={"voidsHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.text, styles.title]}>Stornierungen ({zReport.voids.length}x)</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					{
						zReport.voids.map((voidEntry, i) => {
							return <ListItem key={"void" + i} topDivider bottomDivider containerStyle={[styles.background]}>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>Grund: {t('voidReason_' + voidEntry.reason)}</ListItem.Title>
									<ListItem.Subtitle style={[styles.text]}>{voidEntry.waiter.displayName}</ListItem.Subtitle>
								</ListItem.Content>
								<ListItem.Content style={[]} right={true}>
									<ListItem.Title style={[styles.text, styles.textRight]}>{voidEntry.totalVoidValue.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Title>
									<ListItem.Subtitle style={[styles.text, styles.textRight]}>{voidEntry.voidQuantity}x {voidEntry.articleName}</ListItem.Subtitle>
								</ListItem.Content>
							</ListItem>
						})
					}
					<Divider style={[styles.divider]} />
					<ListItem key={"modifiersHeader"} topDivider bottomDivider containerStyle={[styles.background]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.text, styles.title]}>Rabatte/Zuschläge ({zReport.modifiers.length}x)</ListItem.Title>
						</ListItem.Content>
					</ListItem>
					{
						zReport.modifiers.map((modifier, i) => {
							return <ListItem key={"modifier" + i} topDivider bottomDivider
															 containerStyle={[styles.background]}>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>{modifier.description}</ListItem.Title>
								</ListItem.Content>
								<ListItem.Content style={[]} right={true}>
									{
										modifier.isAbsolute ?
												<ListItem.Subtitle style={[styles.text, styles.textRight]}>{modifier.amount.toLocaleString(locale, {style: 'currency', currency: 'EUR'})}</ListItem.Subtitle>
												:
												<ListItem.Subtitle style={[styles.text, styles.textRight]}>{modifier.amount.toLocaleString(locale, {style: 'decimal'})}%</ListItem.Subtitle>
									}
								</ListItem.Content>
							</ListItem>
						})
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
		)
	}
}
