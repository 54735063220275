import * as Storage from '../storage/storage';
import * as Alert from '../alerts/alerts';
import * as Base64 from 'base-64';
import {AppContextType} from '../AppProvider';
import {ScreenState} from "../types/ScreenState";
import {ServerResponse} from "../types/ServerResponse";
import Constants from "expo-constants";
import * as Sentry from '@sentry/react-native';
import {setSentryUser} from '../util/SentryWrapper';

export async function loadArticles<T extends ScreenState>(setState: (setState: (state: T) => T) => void, appContext: AppContextType): Promise<ServerResponse> {
	return loadStoreData("articles", setState, appContext)
}

export async function sendTableData<T extends ScreenState>(route = "", tableId: string, partyNumber: number, setState: (setState: (state: T) => T) => void, appContext: AppContextType, method = "PUT", body?: RequestPayload, overwriteResponse = true): Promise<ServerResponse> {
	return sendStoreData("table/" + tableId + "/" + partyNumber + "/" + route, setState, appContext, method, body, overwriteResponse)
}

export async function sendStoreData<T extends ScreenState>(route = "", setState: (setState: (state: T) => T) => void, appContext: AppContextType, method = "PUT", body?: RequestPayload, overwriteResponse = true): Promise<ServerResponse> {
	setState((state) => ({
		...state,
		isLoading: true,
	}))
	const storeId = await Storage.getItem("storeId");
	return request(`store/${storeId}/${route}`, setState, appContext, method, body, overwriteResponse)
}

export async function loadStoreData<T extends ScreenState>(route = "", setState: (setState: (state: T) => T) => void, appContext: AppContextType, overwriteResponse = true, ignoreFail = false): Promise<ServerResponse> {
	setRefreshing(setState)
	const storeId = await Storage.getItem("storeId");
	return request(`store/${storeId}/${route}`, setState, appContext, "GET", undefined, overwriteResponse, ignoreFail)
}

function setRefreshing<T extends ScreenState>(setState: (setState: (state: T) => T) => void) {
	setState((state) => {
		return ({
			...state,
			refreshing: true,
		});
	})
}

function isJwtValid(token: string) {
	const base64Url = token.split('.')[1]
	const base64String = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(Base64.decode(base64String).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''))

	const json = JSON.parse(jsonPayload)
	const expire = new Date(json.exp * 1000)
	const now = new Date()

	return now.getTime() < expire.getTime();
}

export type RequestPayload = string | object

export async function request<T extends ScreenState>(route = "", setState: (setState: (state: T) => T) => void, appContext: AppContextType, method = "GET", data?: RequestPayload, overwriteResponse = true, ignoreFail = false): Promise<ServerResponse> {
	setRefreshing(setState)

	let deviceId = Constants.deviceId
	if (deviceId == null) deviceId = Constants.installationId
	if (deviceId == null) deviceId = Constants.deviceName
	if (deviceId == null) deviceId = "" // TODO: find fallback

	const authorization = await Storage.getItem('authorization');
	const impersonate = await Storage.getItem('impersonate');
	if (authorization == null) {
		setState((state) => ({
			...state,
			isLoading: false,
			customError: null,
			response: null
		}))
	} else if (isJwtValid(authorization)) {
		const myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + authorization);
		myHeaders.append("Device-Id", deviceId);
		if (impersonate) {
			myHeaders.append("Impersonate", impersonate);
		}

		const requestOptions = {
			method: method,
			headers: myHeaders,
			redirect: 'follow',
		} as RequestInit;

		if (data !== null && data !== undefined) {
			if ((typeof data) === "string") {
				myHeaders.append("Content-Type", "text/plain; charset=UTF-8");

				requestOptions.body = data as string
			} else {
				myHeaders.append("Content-Type", "application/json; charset=UTF-8");

				requestOptions.body = JSON.stringify(data).replace(/\u00a0/g, '\u0020')
			}

			if (requestOptions.body.length > 1000) {
				console.log(method + " '" + route + "' with payload length over 1000")
			} else {
				console.log(method + " '" + route + "' with payload: " + requestOptions.body)
			}
		} else {
			console.log(method + " '" + route + "'")
		}

		try {
			const rawResponse = await fetch(appContext.environment.baseURL + route, requestOptions)
			const response: ServerResponse = await rawResponse.json()
			const result = response.transactionResponse?.Result
			if (result) {
				Storage.getItem("showWarnings").then(showWarnings => {
					if (showWarnings !== "false" || result.UserMessage) {
						const warnings = result.Warning?.join('\n\n')
						if (result.ErrorCode) {
							let message = ''
							if (result.UserMessage) {
								message += result.UserMessage
								if (warnings) {
									message += '\n\n'
								}
							}
							if (warnings) {
								message += warnings
							}
							Alert.alert('Fehler! (' + result.ErrorCode + ')', message)
						} else if (result.UserMessage) {
							Alert.alert(result.UserMessage, warnings)
						} else if (result.Warning && result.Warning.length > 0) {
							Alert.alert('Warnung!', warnings)
						}
					}
					const stringifiedResult = JSON.stringify(result)
					if (stringifiedResult !== '{"RC":"OK"}') {
						if (result.RC === 'OK') {
							if (result.UserMessage) {
								Sentry.captureMessage(stringifiedResult, 'error');
							} else {
								Sentry.captureMessage(stringifiedResult, 'warning');
							}
						} else {
							Sentry.captureMessage(stringifiedResult, 'fatal');
						}
					}
				})
			}
			if (response.status === "SUCCESS") {
				setState((state) => ({
					...state,
					isLoading: false,
					customError: null,
					response: overwriteResponse ? response : state.response,
					refreshing: false,
				}))
				return response
			} else if (response.status === "ERROR") {
				if (ignoreFail) {
					setState((state) => ({
						...state,
						isLoading: false,
						refreshing: false,
					}))
					Sentry.captureMessage('Request allowed to fail returned error response: ' + JSON.stringify(response), 'warning');
				} else if (overwriteResponse) {
					setState((state) => ({
						...state,
						isLoading: false,
						customError: response.data,
						response: overwriteResponse ? null : state.response,
						refreshing: false,
					}))
				} else {
					setState((state) => ({
						...state,
						isLoading: false,
						refreshing: false,
					}))
					Alert.alert(response.data.title, response.data.description)
				}
			} else {
				if (overwriteResponse) {
					setState((state) => ({
						...state,
						isLoading: false,
						customError: null,
						response: overwriteResponse ? null : state.response,
						refreshing: false,
					}))
				} else {
					setState((state) => ({
						...state,
						isLoading: false,
						refreshing: false,
					}))
					Alert.alert("Fehler!", "Es ist ein unbekannter Fehler aufgetreten: " + JSON.stringify(response))
				}
			}
		} catch (error) {
			setState((state) => ({
				...state,
				isLoading: false,
				customError: null,
				error: error ?? undefined,
				response: overwriteResponse ? null : state.response,
				refreshing: false,
			}))
		}
	} else {
		console.log("Token invalid! Deleting token and reloading app...")
		await Storage.deleteItem("authorization")
		setSentryUser(null)
		appContext.forceReloadApp()
	}
	return Promise.reject()
}

export function timeout(ms: number, promise: Promise<any>) {
	return new Promise((resolve, reject) => {
		const timer = setTimeout(() => {
			reject(new Error('TIMEOUT'))
		}, ms)

		promise
		.then(value => {
			clearTimeout(timer)
			resolve(value)
		})
		.catch(reason => {
			clearTimeout(timer)
			reject(reason)
		})
	})
}
