import 'react-native-gesture-handler';
import * as React from 'react';
import * as Style from '../../theme/style';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../types/RootStackParamList";
import {getAppContext} from "../../AppProvider";
import {ScreenState} from "../../types/ScreenState";
import * as Backend from "../../backend/Backend";
import {ErrorView} from "../elements/ErrorView";
import {LoadingView} from "../elements/LoadingView";
import {ScrollView} from "react-native";
import {Divider, ListItem} from "react-native-elements";
import * as Alert from "../../alerts/alerts";
import {StoreHubInfo} from "../../types/StoreHubInfo";

export default function StoreHubDetailsScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'StoreHubDetails'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: false,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [storeHubInfo, setStoreHubInfo] = React.useState<StoreHubInfo>(route.params.storeHubInfo)

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (storeHubInfo === undefined) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		return (
				<ScrollView style={[styles.container]}>
					<ListItem key={"storeHubInfo"} topDivider bottomDivider
										containerStyle={[styles.background]}>
						<ListItem.Content style={[]}>
							<ListItem.Title style={[styles.text, styles.title]}>{storeHubInfo.displayName || storeHubInfo.hardwareAddress}</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Version: {storeHubInfo.storeHubVersion}</ListItem.Subtitle>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Adresse: {storeHubInfo.hardwareAddress}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					{
							storeHubInfo && !storeHubInfo.paired &&
							<ListItem key={"storeHubInfoPending"} topDivider bottomDivider
												containerStyle={[styles.background]} onPress={() => {
								Alert.confirm("StoreHub einrichten", "Möchtest du dieses StoreHub verbinden?", "Verbinden", "Abbrechen", () => {
									Backend.sendStoreData("hub/pairing/" + storeHubInfo.runtimeId, setState, appContext, "PUT").then(() => navigation.navigate('PairStoreHub'))
								}, undefined, 'default', 'cancel')
							}}>
								<ListItem.Content style={[]}>
									<ListItem.Title style={[styles.text, styles.title]}>Verbinden</ListItem.Title>
								</ListItem.Content>
								<ListItem.Chevron/>
							</ListItem>
					}
					{
							storeHubInfo && storeHubInfo.paired &&
							<>
								<ListItem key={"removeStoreHub"} topDivider bottomDivider
													containerStyle={[styles.background]}
													onPress={() => {
														Alert.confirm("StoreHub entfernen", "Möchtest du dieses StoreHub entfernen?", "Entfernen", "Abbrechen", () => {
															Backend.sendStoreData("hub/pairing/" + storeHubInfo.runtimeId, setState, appContext, "DELETE").then(() => navigation.navigate('PairStoreHub'))
														}, undefined, 'destructive', 'cancel')
													}}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.text, styles.title]}>Verbindung trennen</ListItem.Title>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>

								<Divider style={[styles.divider]} />

								<ListItem key={"softwareRestart"} topDivider bottomDivider
													containerStyle={[styles.background]}
													onPress={() => {
														Alert.confirm("StoreHub Software neustarten", "Möchtest du die Software dieses StoreHubs neustarten?", "Neustarten", "Abbrechen", () => {
															Backend.sendStoreData("hub/" + storeHubInfo.runtimeId + "/action/RESTART_SOFTWARE", setState, appContext, "PUT", undefined, false).then(() => navigation.navigate('Business'))
														}, undefined, 'destructive', 'cancel')
													}}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.text, styles.title]}>Software neustarten</ListItem.Title>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
								<ListItem key={"hardwareRestart"} topDivider bottomDivider
													containerStyle={[styles.background]}
													onPress={() => {
														Alert.confirm("StoreHub Hardware neustarten", "Möchtest du die Hardware dieses StoreHubs neustarten?", "Neustarten", "Abbrechen", () => {
															Backend.sendStoreData("hub/" + storeHubInfo.runtimeId + "/action/REBOOT_HARDWARE", setState, appContext, "PUT", undefined, false).then(() => navigation.navigate('Business'))
														}, undefined, 'destructive', 'cancel')
													}}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.text, styles.title]}>Hardware neustarten</ListItem.Title>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
								<ListItem key={"hardwareShutdown"} topDivider bottomDivider
													containerStyle={[styles.background]}
													onPress={() => {
														Alert.confirm("StoreHub Hardware ausschalten", "Möchtest du die Hardware dieses StoreHubs ausschalten?", "Ausschalten", "Abbrechen", () => {
															Backend.sendStoreData("hub/" + storeHubInfo.runtimeId + "/action/SHUTDOWN_HARDWARE", setState, appContext, "PUT", undefined, false).then(() => navigation.navigate('Business'))
														}, undefined, 'destructive', 'cancel')
													}}>
									<ListItem.Content style={[]}>
										<ListItem.Title style={[styles.text, styles.title]}>Hardware neustarten</ListItem.Title>
									</ListItem.Content>
									<ListItem.Chevron/>
								</ListItem>
							</>
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
