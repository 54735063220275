import 'react-native-gesture-handler';
import * as React from 'react';
import {InfoView} from './elements/InfoView';
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import * as Style from '../theme/style';
import {getAppContext} from '../AppProvider';
import {LocalizationContext} from '../LocalizationContext';
import {ScreenState} from '../types/ScreenState';
import * as Backend from '../backend/Backend';
import {ErrorView} from './elements/ErrorView';
import {LoadingView} from './elements/LoadingView';
import {RefreshControl, ScrollView} from 'react-native';
import {Divider, ListItem} from 'react-native-elements';
import moment from 'moment';
import {Reservation} from '../types/Reservation';
import {Button} from 'react-native-paper';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';

export default function ReservationsScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Reservations'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const { locale } = React.useContext(LocalizationContext);

	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})
	function loadData() {
		Backend.loadStoreData("reservations/upcoming", setState, appContext).then(() => {})
	}
	React.useEffect(loadData, [])
	React.useEffect(() => {
		return navigation.addListener('focus', loadData);
	}, [navigation]);

	React.useLayoutEffect(() => {
		navigation.setOptions({
			headerRight: () => (
					<Button onPress={() => navigation.navigate('AddReservation')}>
						<FontAwesomeIcon icon="plus" size={24} style={[styles.text]}/>
					</Button>
			),
		});
	}, [navigation]);

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const reservations: Reservation[] = state.response.data
		return (
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					<ListItem key={"betaDisclaimer"} topDivider bottomDivider containerStyle={[styles.background]}>
						<FontAwesomeIcon icon="exclamation-triangle" size={32} style={[styles.warningColor]} />
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.title, styles.center, styles.errorText]}>Warnung: Beta-Phase</ListItem.Title>
							<ListItem.Subtitle style={[styles.subTitle, styles.center]}>Diese Funktion befindet sich noch im Aufbau und kann sich in künftigen Updates verändern</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
					<Divider style={[styles.divider]} />
					{
						reservations.map((reservation: Reservation, i) => {
									return (
											<ListItem key={"table" + i} topDivider bottomDivider
																containerStyle={[styles.background]}
																onPress={() => navigation.navigate('ReservationDetails', {id: reservation.id})}>
												<ListItem.Content style={[]}>
													<ListItem.Title style={[styles.text, styles.title]}>{reservation.tableName ?? reservation.additionalNotes}</ListItem.Title>
													<ListItem.Subtitle style={[styles.text, styles.subTitle]}>
														{moment(reservation.startTime, "YYYY-MM-DDTkk:mm:ssSSSZ").format("dddd, DD.MM.")}
													</ListItem.Subtitle>
													<ListItem.Subtitle style={[styles.text, styles.subTitle]}>
														{moment(reservation.startTime, "YYYY-MM-DDTkk:mm:ssSSSZ").format("LT")} - {moment(reservation.endTime, "YYYY-MM-DDTkk:mm:ssSSSZ").format("LT")}
													</ListItem.Subtitle>
													{
															reservation.tableName && reservation.tableName.length !== 0 &&
															<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Tisch: {reservation.tableName}</ListItem.Subtitle>
													}
													{
															// tableName-check because otherwise the notes are already displayed as title above
															reservation.tableName && reservation.additionalNotes && reservation.additionalNotes.length !== 0 &&
															<ListItem.Subtitle style={[styles.text, styles.subTitle]}>Notizen: {reservation.additionalNotes}</ListItem.Subtitle>
													}
												</ListItem.Content>
												<ListItem.Content style={[]} right={true}>
													<ListItem.Title style={[styles.text, styles.title, styles.textRight]}>{reservation.guests} Gäste</ListItem.Title>
												</ListItem.Content>
												<ListItem.Chevron/>
											</ListItem>
									)
								}
						)
					}
					{
							reservations.length === 0 && InfoView(
									"Keine Reservierungen gefunden!",
									"Du kannst eine neue Reservierung hinzufügen, indem du auf das Plus-Symbol oben rechts klickst.\n" +
									"\n" +
									"Vergangene Reservierungen werden aus Datenschutzgründen nach kurzer Zeit gelöscht."
							)
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
		);
	}
}
