import 'react-native-gesture-handler';
import * as React from 'react';
import {RefreshControl, ScrollView, View, ViewStyle} from 'react-native';
import * as Backend from '../backend/Backend';
import {LoadingView} from './elements/LoadingView';
import {ErrorView} from './elements/ErrorView';
import * as Style from '../theme/style';
import {Divider, ListItem} from 'react-native-elements';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {getAppContext} from '../AppProvider';
import {ScreenState} from "../types/ScreenState";
import {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../types/RootStackParamList";
import {OpenTable} from "../types/OpenTable";
import {Floor, Floorplan, Table} from "../types/Floorplan";
import * as Storage from "../storage/storage";
import * as Alert from "../alerts/alerts";
import {UserInfo} from "../types/UserInfo";
import {Reservation} from "../types/Reservation";
import moment from "moment/moment";
import {useFocusEffect} from "@react-navigation/native";

export default function FloorplanScreen({ route, navigation }: StackScreenProps<RootStackParamList, 'Floorplan'>) {
	const styles = Style.getStyles()
	const appContext = getAppContext()
	const [state, setState] = React.useState<ScreenState>({
		isLoading: true,
		customError: undefined,
		error: undefined,
		response: undefined,
		refreshing: false,
	})

	const [waiter, setWaiter] = React.useState<UserInfo|undefined>(route.params?.waiter)

	const [impersonate, setImpersonate] = React.useState<string|undefined>(route.params?.waiter?.id)
	Storage.getItem('impersonate').then((id) => {
		if (id) setImpersonate(id)
	})

	function loadData(ignoreFail: boolean = false) {
		console.log('loading data...')
		Backend.loadStoreData("tables", setState, appContext, true, ignoreFail).then(() => {})
	}

	// initial load
	React.useEffect(() => {
		// 1ms Timeout as workaround for it to work on Web
		const timeoutId = setTimeout(() => loadData(), 1);
		return () => clearTimeout(timeoutId);
	}, []);

	// Reload repeatedly until screen is left (first loadData outside of interval breaks web-version!)
	React.useEffect(() => {
		const intervalId = setInterval(() => loadData(true), 30_000);
		return () => clearInterval(intervalId);
	}, [])

	function navigateToTable(table: Table, openParties: OpenTable[]) {
		if (openParties.length === 0) {
			navigation.navigate('OrderCategories', {tableData: {tableId: table.id!, tableName: table.name, partyNumber: -1}, initialOrder: true})
		} else if (openParties.find((party) => party.waiter.id == impersonate)) {
			navigation.navigate('OpenTable', {tableData: {tableId: table.id!, tableName: table.name, partyNumber: -1}})
		} else {
			Alert.alert("Nicht erlaubt!", "Dieser Tisch wird von einem/r anderen Kellner/in bedient!")
		}
	}

	if (state.error) {
		return ErrorView(null, state.error.message)
	} else if (state.customError !== undefined && state.customError !== null) {
		return ErrorView(state.customError["title"], state.customError["description"])
	} else if (state.isLoading) {
		return LoadingView()
	} else if (!state.response || !state.response.data) {
		return ErrorView("Keine Daten empfangen", "Der Server hat keine Daten gesendet. Dies kann bedeuten, dass die Sitzung abgelaufen ist, oder ein anderer Fehler vorliegt. Bitte starte die Anwendung neu und probiere es erneut.")
	} else {
		const reservations: Reservation[] = state.response.data.reservations
		const openTables: OpenTable[] = state.response.data.tables
		const floorplan: Floorplan = state.response.data.floorplan
		return (
			<>
				{
					waiter &&
					<ListItem key={"waiter"} topDivider bottomDivider containerStyle={[styles.background, {backgroundColor: waiter.roles.find((ar) => ar.organization.id == appContext.storeId)?.hexCode}]}>
						<ListItem.Content style={[styles.center]}>
							<ListItem.Title style={[styles.text, styles.title]}>{waiter.displayName}</ListItem.Title>
							<ListItem.Subtitle style={[styles.text, styles.subTitle]}>{waiter.roles.find((ar) => ar.organization.id == appContext.storeId)?.roleDisplay}</ListItem.Subtitle>
						</ListItem.Content>
					</ListItem>
				}
				<ScrollView style={[styles.container]} refreshControl={<RefreshControl onRefresh={loadData} refreshing={state.refreshing} />}>
					{
						floorplan.floors.map((floor: Floor, i) => {
							return (
								<View key={"view" + i}>
									<ListItem key={"floor" + i} topDivider bottomDivider containerStyle={[styles.background]}>
										<FontAwesomeIcon icon="folder" size={16} style={[styles.text]}/>
										<ListItem.Content>
											<ListItem.Title style={[styles.text, styles.title]}>{floor.name}</ListItem.Title>
										</ListItem.Content>
									</ListItem>
									{
										/* Icons:
                                            Table Available: circle
                                            Table Reserved: clock
                                            Looking on the menu: book-open
                                            Waiting on order: spinner
                                            Consuming order: utensils
                                            Waiting to Pay: hand-holding-usd
                                            About to leave: walking
                                         */
										floor.tables.map((table: Table) => {
												const openParties = openTables.filter((openTable: OpenTable) => openTable.tableId === table.id)
												const tableReservations = reservations.filter((res: Reservation) => res.tableId === table.id)
												let icon
												let subtitle: string[] = []
												if (openParties.length === 0) { // Table not occupied
													if (tableReservations.length === 0) {
														icon = (<FontAwesomeIcon icon="circle" size={32}
																				 style={[styles.text, {
																					 color: '#29bc00',
																				 } as ViewStyle]}/>)
													} else {
														icon = (<FontAwesomeIcon icon="clock" size={32}
																				 style={[styles.text, {
																					 color: '#0096bc',
																				 } as ViewStyle]}/>)
													}
												} else if (openParties.find((party) => party.waiter.id == impersonate)) { // User is assigned here as a waiter
													icon = (<FontAwesomeIcon icon="utensils" size={32}
																			 style={[styles.text, {
																				 color: '#ff7700',
																			 } as ViewStyle]}/>)
													subtitle = openParties.map((openTable: OpenTable) => "Partei " + openTable.partyNumber + ": " + openTable.duration + " (" + openTable.waiter.displayName + ")")
												} else { // Table is assigned to another waiter
													icon = (<FontAwesomeIcon icon="user-slash" size={32}
																			 style={[styles.text, {
																				 color: '#cb0d0d',
																			 } as ViewStyle]}/>)
													subtitle = openParties.map((openTable: OpenTable) => "Partei " + openTable.partyNumber + ": " + openTable.duration + " (" + openTable.waiter.displayName + ")")
												}
												if (tableReservations.length > 0) {
													subtitle = [...subtitle, ...tableReservations.map((res: Reservation) => "Reservierung um " + moment(res.startTime, "YYYY-MM-DDTkk:mm:ssSSSZ").format('LT') + ": " + res.customer?.fullName + " (" + res.guests + "x)")]
												}
												return (
													<ListItem key={"table" + table.id} topDivider bottomDivider
															  containerStyle={[styles.background]}
															  onPress={() => navigateToTable(table, openParties)}>
														{icon}
														<ListItem.Content style={[styles.center]}>
															<ListItem.Title style={[styles.text, styles.title]}>{table.name}</ListItem.Title>
															{
																subtitle == null || subtitle.length === 0 ?
																	<ListItem.Subtitle key={table.id + "subtitle"} style={[styles.text, styles.subTitle]}>frei</ListItem.Subtitle>
																	:
																	subtitle.map((text: string, j: number) =>
																		<ListItem.Subtitle key={table.id + "subtitle" + j} style={[styles.text, styles.subTitle, styles.center]}>{text}</ListItem.Subtitle>
																	)
															}
														</ListItem.Content>
														<ListItem.Chevron/>
													</ListItem>
												)
											}
										)
									}
									<Divider style={[styles.divider]} />
								</View>
							)
						})
					}
					{
						floorplan.floors.length === 0 &&
						<ListItem key={"noFloors"} topDivider bottomDivider containerStyle={[styles.background]} onPress={() => navigation.navigate('EditFloorplan')}>
							<ListItem.Content style={[styles.center]}>
								<ListItem.Title style={[styles.text, styles.title, styles.center]}>Kein Tischplan hinterlegt</ListItem.Title>
								<ListItem.Subtitle style={[styles.text, styles.subTitle, styles.center]}>Um den Tischplan zu bearbeiten, hier klicken</ListItem.Subtitle>
							</ListItem.Content>
							<ListItem.Chevron/>
						</ListItem>
					}
					<Divider style={[styles.divider]} />
				</ScrollView>
			</>
		);
	}
}
